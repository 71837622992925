import useI18n from '@/composables/use-i18n'
import { computed, useContext } from '@nuxtjs/composition-api'

// FIXME: 動態管理首頁輪播的店家列表
const AD_HOME_CAROUSEL_STORE_IDX = [516, 485]

export type HomeCarouselItem = {
  key: string,
  img?: string,
  to?: string // 站內路由
  href?: string // 站外網址
  hrefLocaleMap?: Record<string, string> // 不同語言的站外網址
}

export default function () {
  const { localePath } = useContext()
  const { locale } = useI18n()

  const originalList = computed<Array<HomeCarouselItem>>(() => [
    {
      key: 'recommend',
      hrefLocaleMap: {
        'zh-tw': 'https://gogoout.com/blog/signup-referred-announcement/',
        en: 'https://gogoout.com/blog/signup-referred-announcement-en/',
        ja: 'https://gogoout.com/blog/signup-referred-announcement-en/',
      },
    },
    ...AD_HOME_CAROUSEL_STORE_IDX.map(id => ({
      key: `ad-store-${id}`,
      to: localePath({ path: `/store/${id}`, query: { ap: '1' } }),
    })),
    {
      key: 'tyo',
      to: localePath('/search/city/JP13B'),
    },
    {
      key: 'oka',
      to: localePath('/search/city/JP47B'),
    },
    {
      key: 'hl',
      to: localePath('/search/city/HL'),
    },
    {
      key: 'kmj',
      to: localePath('/search/city/JP43A'),
    },
    {
      key: 'go-points',
    },
  ])

  /**
   * 這邊要注意:
   * 因為是透過 imageKeys 去 mapping 原圖及 webp圖片
   * 因此圖片命名遵循
   * <locale>-<imageKey>.[png|webp]
   * e.x. zh-tw-oka.webp
   */
  const list = computed<Array<HomeCarouselItem>>(() => {
    return originalList.value.map(item => ({
      img: `https://cdn.gogoout.com/web/index/carousel/${locale}-${item.key}.jpg`,
      ...(item.hrefLocaleMap && {
        href: item.hrefLocaleMap[locale],
      }),
      ...item,
    }))
  })

  return {
    list,
  }
}
